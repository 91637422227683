
import React from 'react'
import teamJHZ from '../media/creator/ProfilePic Creator - Team JHZ.webp'
import pretattica from '../media/creator/ProfilePic Creator - Pretattica.webp'
import fantaballa from '../media/creator/ProfilePic Creator - Fantaballa.webp'
import davi_ambro from '../media/creator/ProfilePic Creator - davi_ambro.webp'
import tonysalumiere from '../media/creator/ProfilePic Creator - TonyIlSalumiere.webp'
import michelapilatti from '../media/creator/ProfilePic Creator - michelapilatti.webp'
import mattiadeitaluni from '../media/creator/ProfilePic Creator - mattia_deitaluni.webp'
import giankoo from '../media/creator/ProfilePic Creator - giankooo.webp'
import stella from '../media/creator/ProfilePic Creator - 123stellaofficial.webp'
import subzero from '../media/creator/ProfilePic Creator - sub_zero410.webp'
import andreSawi from '../media/creator/ProfilePic Creator - andresawi.webp'
import Ciuffo from '../media/creator/ProfilePic Creator - ciuffogamer.webp'
import sankace from '../media/creator/ProfilePic Creator - sank_ace.webp'
import Claudia from '../media/creator/ProfilePic Creator - claudietta_fn.webp'
import Rosyberty from '../media/creator/ProfilePic Creator - rosyberty.webp'
import Nonno from '../media/creator/ProfilePic Creator - nonnoerotiko.webp'
import GianmarcoFede from '../media/creator/ProfilePic Creator - giamm4fed3.webp'
import Ludo from '../media/creator/ProfilePic Creator - ludo.tet.webp'
import theking from '../media/creator/ProfilePic Creator - thekinglisander.webp'
import ionumero from '../media/creator/ProfilePic Creator - io.numero.perfett.webp'
import ilcotto from '../media/creator/ProfilePic Creator - ilcotto_cdk.webp'
import angelabaro from '../media/creator/ProfilePic Creator - angelabarone_.webp'
import lafamiglianerd from '../media/creator/ProfilePic Creator - lafamiglianerd.webp'
import Carodecastello from '../media/creator/ProfilePic Creator - carodecastello-1.webp'
import PartyTuning from '../media/creator/ProfilePic Creator - partytuning.webp'
import Veesky from '../media/creator/ProfilePic Creator - veeskyyontiktok.webp'
import cuciniamo from '../media/creator/ProfilePic Creator - cuciniamoinsiemeacasaa.webp'
import fenomeno from '../media/creator/ProfilePic Creator - ilfenomenopeter.webp'

export const creators = [
    {id:1, nome: 'TeamJHZ', src: teamJHZ, tiktok: 'https://www.tiktok.com/@teamjhz', categorie: ['Gaming'] },
    {id:2, nome: 'PreTattica_official', src: pretattica, tiktok: 'https://www.tiktok.com/@pretattica_official', categorie: ['Sport'] },
    {id:3, nome: 'Fantaballa', src: fantaballa, tiktok: 'https://www.tiktok.com/@fantaballa', categorie: ['Gaming'] },
    {id:4, nome: 'davi_ambro', src: davi_ambro, tiktok: 'https://www.tiktok.com/@davi_ambro', categorie: ['Vlog personali'] },
    { id:5, nome: 'TonyilSalumiere', src: tonysalumiere, tiktok: 'https://www.tiktok.com/@tonyilsalumiere', categorie: ['Gaming', 'Sport', 'Intrattenimento'] },
    { id:6,nome: 'Michelapilatti', src: michelapilatti, tiktok: 'https://www.tiktok.com/@michelapilatti', categorie: ['Vlog personali'] },
    {id:7, nome: 'Mattia_deiTaluni', src: mattiadeitaluni, tiktok: 'https://www.tiktok.com/@mattia_deitaluni', categorie: ['Vlog personali', 'Musica e Canto', 'Sport', 'Altro'] },
    {id:8, nome: 'Giankooo', src: giankoo, tiktok: 'https://www.tiktok.com/@giankooo', categorie: ['Vlog personali'] },
    { id:9,nome: '123StellaOfficial', src:stella, tiktok: 'https://www.tiktok.com/@123stellaofficial', categorie: ['Gaming'] },
    { id:10,nome: 'sub_zero410', src: subzero, tiktok: 'https://www.tiktok.com/@andrien1102', categorie: ['Gaming'] },
    { id:11,nome: 'AndreSawi', src: andreSawi, tiktok: 'https://www.tiktok.com/@andresawi', categorie: ['Gaming'] },
    { id:12,nome: 'Ciuffo Gamer', src: Ciuffo, tiktok: 'https://www.tiktok.com/@ciuffogamer', categorie: ['Gaming'] },
    {id:13, nome: 'Sank_Ace', src: sankace, tiktok: 'https://www.tiktok.com/@sank_ace', categorie: ['Gaming'] },
    { id:14,nome: 'Claudia', src: Claudia, tiktok: 'https://www.tiktok.com/@claudietta_fn', categorie: ['Gaming', 'Vlog personali'] },
    {id:15, nome: 'RosyBerty', src: Rosyberty, tiktok: 'https://www.tiktok.com/@rosyberty', categorie: ['Viaggi e Avventure', 'Recensioni e Unboxing', 'Vlog personali', 'Animali'] },
    { id:16,nome: 'NonnoErotiko', src: Nonno, tiktok: 'https://www.tiktok.com/@nonnoerotiko', categorie: ['Vlog personali', 'Balli e Coreografie'] },
    { id:17,nome: 'Gianmarco Fede', src: GianmarcoFede, tiktok: 'https://www.tiktok.com/@giamm4fed3', categorie: ['Gaming'] },
    { id:18,nome: 'Ludo.tet', src: Ludo, tiktok: 'https://www.tiktok.com/@ludo.tet', categorie: ['Beauty e Makeup', 'Cucina', 'Vlog personali', 'Relazioni e Consigli di Coppia', 'Altro'] },
    { id:19,nome: 'thekinglisander', src: theking, tiktok: 'https://www.tiktok.com/@thekinglisander', categorie: ['Gaming'] },
    { id:20,nome: 'io.numero.perfett', src: ionumero, tiktok: 'https://www.tiktok.com/@io.numero.perfett', categorie: ['Vlog personali'] },
    { id:21,nome: 'ilCotto_cdk', src: ilcotto, tiktok: 'https://www.tiktok.com/@ilcotto_cdk', categorie: ['Vlog personali'] },
    { id:22,nome: 'angelabarone_', src: angelabaro, tiktok: 'https://www.tiktok.com/@angelabarone_', categorie: ['Vlog personali'] },
    { id:23,nome: 'la famiglia Nerd', src: lafamiglianerd, tiktok: 'https://www.tiktok.com/@lafamiglianerd', categorie: ['Gaming'] },
    { id:24,nome: 'Carodecastello_TV', src: Carodecastello, tiktok: 'https://www.tiktok.com/@carodecastello', categorie: ['Vlog personali', 'Balli e Coreografie'] },
    { id:25,nome: 'PartyTuning', src: PartyTuning, tiktok: 'https://www.tiktok.com/@partytuning', categorie: ['Gaming'] },
    { id:26,nome: 'VeesKyyOnTiKToK', src: Veesky, tiktok: 'https://www.tiktok.com/@veeskyyontiktok', categorie: ['Gaming'] },
    { id:27,nome: 'cuciniamoinsiemeacasaa', src: cuciniamo, tiktok: 'https://www.tiktok.com/@cuciniamoinsiemeacasaa', categorie: ['Cucina', 'Vlog personali'] },
    {id:28, nome: 'Il fenomeno peter', src: fenomeno, tiktok: 'https://www.tiktok.com/@ilfenomenopeter', categorie: ['Vlog personali'] },
  ];