import { StaffType } from "./StaffType";
import Sodano from '../media/staff/ProfilePic Collaboratore - Davide Sodano.webp'
import Ieno from '../media/staff/ProfilePic Collaboratore -  Ivan Ieno.webp'
import Ricci from '../media/staff/ProfilePic Collaboratore - Riccardo Ricci.webp'
import Settembrino from '../media/staff/ProfilePic Collaboratore -  Giuseppe Settembrino.webp'
export const Staff : StaffType[]=[{
    id:1,
    nome: 'Davide Sodano',
    ruolo:'Creator Manager',
    linkedin:'https://www.linkedin.com/in/davide-sodano-11b652213/',
    tiktok:'https://www.tiktok.com/@jsoda_',
    src:Sodano

},
{
    id:2,
    nome: 'Ivan Ieno',
    ruolo:'Creator Supporter',
    linkedin:'https://www.linkedin.com/in/ivan-ieno-169381178/',
    tiktok:'https://www.tiktok.com/@onizuka86rm',
    src:Ieno


},
{
    id:3,
    nome: 'Riccardo Ricci',
    ruolo:'Onboarding Operator',
    linkedin:'https://www.linkedin.com/in/riccardo-ricci-a2b6a7224/',
    tiktok:'https://www.tiktok.com/@the.dunk3r',
    src:Ricci


},
{
    id:4,
    nome: 'Giuseppe Settembrino',
    ruolo:'Scouting Operator',
    linkedin:'https://www.linkedin.com/in/giuseppe-settembrino-97022430b/',
    tiktok:'https://www.tiktok.com/@peppewolf7',
    src:Settembrino


}
]
